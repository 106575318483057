import React from "react";
import loadable from "@loadable/component";
import LpLayout from "../../components/Layout/lp-layout";
import SEO from "../../components/seo";
import { LandingPageTestimonials } from "../../data/restaurant-data";
import { SrTemplate } from "../../data/landing/sr-lp";
// import LpLeadForm from "../../components/ConversionPath/LpLeadForm";
// import Image from "../../components/image";
// import TestmonialReviews from "../../components/TestimonialReviews/TestmonialReviews";
// import PartnerLogos from "../../components/PartnerLogos/PartnerLogos";
// import Badges from "../../components/ConversionPath/Badges";
// import UspFeatures from "../../components/ConversionPath/UspFeatures";

import ogImage from "../../images/global_assets/og-image.png";

const LpLeadForm = loadable(() =>
  import("../../components/ConversionPath/LpLeadForm")
);
const Image = loadable(() => import("../../components/image"));
const TestmonialReviews = loadable(() =>
  import("../../components/TestimonialReviews/TestmonialReviews")
);
const PartnerLogos = loadable(() =>
  import("../../components/PartnerLogos/PartnerLogos")
);
const Badges = loadable(() => import("../../components/ConversionPath/Badges"));
const UspFeatures = loadable(() =>
  import("../../components/ConversionPath/UspFeatures")
);

export default function RestaurantLP() {
  return (
    <LpLayout phoneNumber="+18882246506" phoneText="(888) 224-6506">
      <SEO
        title="SpotOn Restaurant Demo | Point-of-Sale"
        description="Run and grow your business more efficiently than ever with a powerful point-of-sale that helps you stay connected with your customers"
        image={`https://spoton.com/${ogImage}`}
        robotsData="noindex, nofollow"
      />
      <section className="demo" style={{ marginTop: 0, paddingTop: 0 }}>
        <div className="demo__content-wrapper">
          <div className="header-img">
            <Image imageName="sr-lp.png" className="lp-img" />
          </div>
          <div className="form-wrapper is-landing">
            <LpLeadForm
              header="Best-in-class restaurant POS system"
              subHeader="Adapt and grow your restaurant with an end-to-end solution built by
            restaurateurs. Call us now:"
              // phoneNumber="tel:+18882246506"
              leadType="lead-gen"
              // phoneText="(888) 224-6506"
              formId="fa94fbc3-3811-4414-a05a-802b962f32ec"
              thankYouPath="/lp/thank-you"
            />
          </div>
        </div>
        <Badges />
        <section>
          <UspFeatures data={SrTemplate} />
        </section>
        <section style={{ marginBottom: 60 }}>
          <PartnerLogos styles={{ marginBottom: 40 }} />
          <TestmonialReviews sectionData={LandingPageTestimonials} />
        </section>
      </section>
    </LpLayout>
  );
}
